.recent_items_header p {
    font-size: 14px;
    padding: 5px;
    text-align: center;
    background-color: #1797c0;;
    color: #fff;
    font-weight: 500;
    border-radius: 5px;
    font-family: Arial, Helvetica, sans-serif;
  }

  .recent_items_list {
    background-color: #e7f3f9;
    border-radius: 5px;
    padding: 10px;
  }

  .recent_items_list ul li {
    font-size: 12px;
    font-family: Arial, Helvetica, sans-serif;
  }

  .recent_items_list ul li a {
    color: black;
  }