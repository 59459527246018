.btn-sm.dropdown-toggle {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
    background-color: #007bff;
    border-color: #007bff;
  }
  
  .btn-sm.dropdown-toggle:hover {
    background-color: #0069d9;
    border-color: #0062cc;
  }
  
  .btn-sm.dropdown-toggle:focus {
    box-shadow: none;
  }
  
  .dropdown-toggle::after {
    display: none;
  }


  .box {
    border: none;
  }