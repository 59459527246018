* {
    margin: 0;
    padding: 0;
    font-family: Arial, Helvetica, sans-serif;
  }

  .dashboard-container {
    display: flex;
    padding: 20px;
  }

  .recent-items {
    width: 15%;
    padding: 5px;
    margin-right: 10px;
  }

  .recent-items h2 {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .recent-items ul {
    list-style: none;
    padding: 0;
  }

  .recent-items li {
    margin-bottom: 10px;
    font-size: 14px;
  }

  .accounts {
    width: 85%;
  }

  .accounts-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .accounts-header h2 {
    margin: 0;
    font-size: 24px;
  }

  .add-account-btn {
    background-color: #ff7f50;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
  }

  .accounts-content {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .view-controls {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .recent-accounts h3 {
    margin-bottom: 15px;
  }

  .account-search {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  .data_table {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .data_table input {

    display: none;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 200px;
    margin-bottom: 5px;
    margin-left: auto;
  }

  /* table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    overflow-y: scroll;
  }

  table th,
  table td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
    width: 200px !important;
  }

  table th {
    background-color: #f7f7f7;
  }

  .MuiTablePagination-toolbar{
      display: flex !important;
      align-items: baseline !important;
  } */

  .MuiTablePagination-toolbar {
    display: flex !important;
    align-items: baseline !important;
  }

  .view-controls {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: flex-start;
  }

  .view-controls select {
    margin-right: 10px;
    /* Reduces the space between the dropdown and the links */
  }

  .view-controls a {
    text-decoration: none;
    color: #007bff;
    /* Adjust the color to your preference */
    margin-left: 5px;
  }

  .view-controls a:hover {
    text-decoration: underline;
  }

  .select-modifier-container {
    width: 200px;
  }

  .select-modifier-container select {
    width: 100%;
    padding: 5px 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f0f0f0;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
  }

  .select-modifier-container select:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    outline: none;
  }

  .select-container {
    width: 300px;
    position: relative;
  }

  .select-container select {
    width: 100%;
    padding: 5px 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f0f0f0;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
  }

  .select-container select:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    outline: none;
  }

  .button-container button {
    padding: 5px 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    color: #fff;
    border-radius: 5px;
    border: none;
    background: #ff7f50;
    margin-left: 5px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .view_label {
    font-weight: 500;
    margin-right: 5px;
    font-size: 14px;
  }

  .button-container button:hover {
    background-color: #0056b3;
  }

  .view_actions {
    margin-left: 5px;
    font-size: 14px;
  }

  .recent-accounts {
    border-top: 4px solid #4273a8;
    border-right: 2px solid #f0f0f0;
    border-left: 2px solid #f0f0f0;
    border-bottom: 2px solid #f0f0f0;
    border-radius: 5px;
    padding-top: 10px;
  }

  .recemt_contract_content {
    padding: 5px;
  }

  .spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  option {
    display: flex;
    align-items: center;
    padding: 4px;
  }

  /* Loader */

  .rwood_spinner img {
    width: 100px;
    display: block;
    margin: 0 auto;
  }

  .contract_data_status {
    text-align: center;
    color: #ff7f50;
    font-size: 20px;
  }

  .rwood_view_edit {
    color: #007bff;
    font-weight: 500;
    cursor: pointer;
  }

  .rwood_view_edit:hover {
    color: #ff7f50;
    text-decoration: underline;
  }