.rwood_report_action_btn {
  width: 600px;
  display: flex;
  justify-content: flex-end;
}

/* doc generator */
.preview_table_drop_area {
  border: 2px dashed #ccc;
  padding: 20px;
  min-height: 100px;
  background-color: #f9f9f9;
  margin-top: 20px;
}

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  display: flex;
  height: 100vh;
}

.main-content {
  width: 80%;
  height: 100vh;
  display: flex;
  padding: 0 6px;
  flex-direction: column;
  box-sizing: border-box;
}

.filters {
  display: flex;
  flex-direction: column;
  padding: 5px;
  border-radius: 6px;
  /* background-color: #f1f1f1; */
  background: linear-gradient(#e5f2f6, #cde8f0);
  border-bottom: 1px solid #ccc;
}

.filters input,
.filters select {
  margin-left: 10px;
}

.add_filter_fields{
  display: flex;
  align-items: center;
  margin: 5px 5px 5px 0;
}

.filter_label {
  margin: 2px;
  padding: 3px;
  font-size: 13px;
  color: #000;
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
} 

 

.rwood_reports_date_fields{
  margin-top: 5px;
} 

.preview {
  flex-grow: 1;
  overflow-y: auto;
  margin-top: 5px;
}

.preview table {
  width: 100%;
  border-collapse: collapse;
}

.preview th,
.preview td {
  font-size: 12px;
  padding: 8px;
  border: 1px solid #ccc;
  text-align: left;
}

.preview th {
  font-size: 12px;
  background-color: #f2f2f2;
}

.tree {
  list-style-type: none;
  padding-left: 20px;
}

/* .caret {
  font-size: 15px;
  font-family: Arial, Helvetica, sans-serif;
  cursor: pointer;
  user-select: none;
} */

.caret::before {
  content: "\25B6";
  color: black;
  display: inline-block;
  margin-right: 6px;
}

.caret-down::before {
  transform: rotate(90deg);
}

.nested {
  display: none;
  list-style-type: none;
}

 

.selected {
  background-color: #e0e0e0;
}

.parent {
  font-weight: bold;
}

 
 
.preview {
  flex-grow: 1;
  overflow-y: auto;
}

.preview table {
  width: 100%;
  border-collapse: collapse;
}

.preview th,
.preview td {
  padding: 8px;
  border: 1px solid #ccc;
  text-align: left;
}

.preview th {
  background-color: #f2f2f2;
}

.tree {
  list-style-type: none;
  padding-left: 20px;
}

 

.caret::before {
  content: "\25B6";
  color: black;
  display: inline-block;
  margin-right: 6px;
}

.caret-down::before {
  transform: rotate(90deg);
}

.nested {
  display: none;
}

.caret-down + .nested {
  display: block;
}

.selected {
  background-color: #e0e0e0;
}

.parent {
  font-weight: bold;
}

ul.tree,
ul.tree ul {
  list-style-type: none;
  padding-left: 20px;
  margin: 0;
}

ul.tree ul {
  display: none;
  padding-left: 20px;
  margin-top: 10px;
}

.caret {
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  padding: 5px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 4px;
  font-family: Arial, Helvetica, sans-serif;
  transition: background-color 0.3s;
}

.caret:hover {
  background-color: #f0f0f0;
}

.parent::before {
  content: "▶";
  color: #555;
  display: inline-block;
  margin-right: 10px;
  transition: transform 0.3s;
}

.caret-down::before {
  transform: rotate(90deg);
}

.selected {
  background-color: #d0e0fb;
}

.caret-down + .nested {
  display: block;
  animation: fadeIn 0.3s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.filter-options {
  display: flex;
  align-items: center;
  padding: 0 5px;
}

.modal-buttons {
  margin: 0 8px;
}

 
.report-container {
  font-family: Arial, sans-serif;
  margin: 20px;
}

.report-container label {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 10px;
}

.report-container input[type="checkbox"] {
  margin-right: 10px;
}

.report-selector {
  width: 600px;
  height: 50vh;
  overflow-y: scroll;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  background-color: #f9f9f9;
}

#search-report {
  width: 100%;
  padding: 5px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  box-sizing: border-box;
}

.tree-container {
  font-family: Arial, sans-serif;
  padding-left: 10px;
}

.tree-item {
  margin: 5px 0;
  font-size: 13px;
}

.tree-label {
  cursor: pointer;
  display: flex;
  padding: 3px 2px;
  align-items: center;
}

.folder-icon,
.file-icon {
  width: 12px;
  height: 12px;
  margin-right: 8px;
}

.tree-children {
  list-style-type: none;
  padding-left: 15px;
}

.tree-root {
  list-style-type: none;
  padding-left: 10px;
}

.tree-label {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.folder-icon,
.file-icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.tree-children {
  list-style-type: none;
  padding-left: 15px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.tree-children li {
  transition: background-color 0.3s ease;
  padding: 3px 5px;
  border-radius: 5px;
}

.tree-children li:hover {
  background-color: rgba(0, 0, 0, 0.1);
  /* Light background on hover */
}

.tree-item.expanded .tree-children {
  max-height: 500px;
}

.button {
  padding: 5px 15px;
  font-size: 15px;
  border: 1px solid #ccc;
  color: #fff;
  border-radius: 5px;
  border: none;
  background: #ff7f50;
  margin-left: 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #0056b3;
}

/* report generator */

.reports_top_banner {
  border-bottom: 1px solid #2aabab;
  background: linear-gradient(#e5f2f6, #cde8f0);
  padding: 5px;
}

.report_btn {
  margin: 2px;
  padding: 3px;
  border-radius: 5px;
  font-size: 13px;
  font-family: Arial, Helvetica, sans-serif;
  border: 1px solid rgb(158, 153, 153);
  outline: none;
}

.report_btn:hover {
  background-color: #ff7f50;
  color: #f9f9f9;
}

.save,
.save_as,
.close,
.run_report {
  width: 80px !important;
}

.report_prop,
.add_report_type {
  width: 150px !important;
}

.report_container {

  display: flex;
  padding: 6px;

}

.drag-drop-text {
  padding: 8px;
  font-size: 12px;
  color: #6c757d;
  background-color: #fff3cd;
  border-bottom: 1px solid #e0e0e0;
  text-align: center;
}

.fields-container {
  width: 20%;
  height: 100vh;
  overflow: auto;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* Search bar and filter buttons */
.header {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 6px 10px;
  background-color: #f1f3f5;
  font-size: 13px;
  border-bottom: 1px solid #e0e0e0;
}

 
.report-search-bar{
    display: flex;
    padding: 6px 10px;
    background-color: #f1f3f5;

} 

.search-bar {
  flex-grow: 1;
  padding: 6px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.filter-icons button {
  background-color: #e9ecef;
  border: none;
  margin-right: 2px;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
}

.filter-icons button.active {
  background-color: #007bff;
  color: white;
}

.filter-icons button:hover {
  background-color: #dee2e6;
}

.report_action_btn {
  color: #ff7f50;
}

.report_action_btn img {
  width: 12px;
}


.rwood_reports_key_items{
  list-style-type: none;
  padding: 0;
}


#show{
  padding: 5px 25px;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  font-size: 14px;
  background-color: #fff;
  transition: all 0.3s ease;
  outline: none;
  color: var(--font-color);
}

select:hover, input[type="text"]:hover {
  border-color: var(--primary-color);
}

select:focus, input[type="text"]:focus {
  border-color: var(--focus-color);
  box-shadow: 0 0 8px rgba(76, 175, 80, 0.2);
}

input[type="text"]::placeholder {
  color: #aaa;
}

.custom_date{

   
  padding: 5px 25px;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  font-size: 14px;
  background-color: #fff;
  transition: all 0.3s ease;
  outline: none;
  color: var(--font-color);
}




.custom-filter-modal{

  display: flex;
  flex-direction: column;
  padding: 5px;
  margin-top: 5px;
  border-radius: 6px;
  background: linear-gradient(#e5f2f6, #cde8f0);
  border-bottom: 1px solid #ccc;

}

.field_filters {
  padding: 5px 20px;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  font-size: 14px;
  background-color: #fff;
  margin: 5px 10px;
  transition: all 0.3s ease;
  outline: none;
  color: var(--font-color);
}

.custom-filter-modal h3{
  font-size: 15px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  margin-left: 15px;
}

.filter_conditions{

  background-color: #f1f1f1;
  min-height: 100px;
  max-height: 200px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  padding: 5px;
  margin-top: 5px;
  border-radius: 6px;
  border-bottom: 1px solid #ccc;

}

.filter_condition_items{
  display: flex;
  align-items: center;
}

.filter_conditions_inputs{

  background-color: #f9e08e;
  padding: 3px 25px;
  border-radius: 6px;
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
  margin: 5px;

}

 
.filter_condition_remove {
  cursor: pointer;
}

.filter_condition_remove img{
  width: 20px;
}